var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.data.properties.filed_content != 'Hide')?_c('div',{style:(_vm.getStyle)},[(
        _vm.data.heading_type == 'H1' &&
        _vm.data.properties &&
        _vm.data.properties.filed_content != 'Hide'
      )?_c('h1',{staticClass:"heading",style:(_vm.getFontStyle('H1'))},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")]):_vm._e(),(
        _vm.data.heading_type == 'H2' &&
        _vm.data.properties &&
        _vm.data.properties.filed_content != 'Hide'
      )?_c('h2',{staticClass:"heading",style:(_vm.getFontStyle('H2'))},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")]):_vm._e(),(
        _vm.data.heading_type == 'H3' &&
        _vm.data.properties &&
        _vm.data.properties.filed_content != 'Hide'
      )?_c('h3',{staticClass:"heading",style:(_vm.getFontStyle('H3'))},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")]):_vm._e(),(
        _vm.data.heading_type == 'H4' &&
        _vm.data.properties &&
        _vm.data.properties.filed_content != 'Hide'
      )?_c('h4',{staticClass:"heading",style:(_vm.getFontStyle('H4'))},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")]):_vm._e(),(
        _vm.data.heading_type == 'H5' &&
        _vm.data.properties &&
        _vm.data.properties.filed_content != 'Hide'
      )?_c('h5',{staticClass:"heading",style:(_vm.getFontStyle('H5'))},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")]):_vm._e(),(
        _vm.data.heading_type == 'H6' &&
        _vm.data.properties &&
        _vm.data.properties.filed_content != 'Hide'
      )?_c('h6',{staticClass:"heading",style:(_vm.getFontStyle('H6'))},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")]):_vm._e(),(_vm.isActive && _vm.data.properties.filed_content != 'Hide')?_c('span',{staticClass:"setting-icon",on:{"click":_vm.openSettings}},[_c('i',{staticClass:"el-icon-s-tools"})]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }